@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
*,
*::after,
*::before {
  /* inherit from html and apply everywhere  */
  box-sizing: inherit;
}

html {
  /* borders and paddings removed from width and height  */
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

b,
strong {
  font-weight: 700;
}

em,
i {
  font-style: italic;
}

mark {
  background: #FFFFFF;
  color: #0087D2;
}

hr {
  border: 0;
  border-top: 1px solid #FFFFFF;
  margin-bottom: 20px;
  margin-top: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.1;
}

h1 {
  font-size: 30px;
  margin: 20px 0;
}

h2 {
  font-size: 26px;
  margin: 20px 0;
}

h3 {
  font-size: 22px;
  margin: 20px 0;
}

h4 {
  font-size: 18px;
  margin: 10px 0;
}

h5 {
  font-size: 14px;
  margin: 10px 0;
}

h6 {
  font-size: 12px;
  margin: 10px 0;
}

a,
a:link,
a:visited,
a:focus,
a:active {
  color: #0087D2;
  text-decoration: none;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a:hover,
a:link:hover,
a:visited:hover,
a:focus:hover,
a:active:hover {
  color: #000000;
  text-decoration: none;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 20px;
}

ul > li,
ol > li {
  margin-top: 0;
  margin-bottom: 10px;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}

dl {
  margin-bottom: 20px;
  margin-top: 0;
}

dt {
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 0;
}

dd {
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 0;
}

input[type=text],
input[type=password],
input[type=url],
input[type=tel],
input[type=search],
input[type=number],
input[type=datetime],
input[type=email] {
  background: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #FFFFFF;
  border-radius: 0;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  height: 32px;
  line-height: 1.4;
  padding: 0 9px;
  vertical-align: baseline;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

select {
  background: #FFFFFF no-repeat center right/8px 4px;
  background-clip: padding-box;
  border: 1px solid #FFFFFF;
  border-radius: 0;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  height: 32px;
  line-height: 1.4;
  padding: 5px 10px 4px;
  vertical-align: baseline;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
select:hover {
  border-bottom-color: #0087D2;
}
select::-ms-expand {
  display: none;
}

textarea {
  background: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #FFFFFF;
  border-radius: 0;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  height: auto;
  line-height: 1.4;
  margin: 0;
  padding: 10px;
  vertical-align: baseline;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  resize: vertical;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #0087D2;
}

._keyfocus *:focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

button:active,
.cart.table-wrapper .actions-toolbar > .action:active,
.cart.table-wrapper .action-gift:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.action.primary {
  font-family: "Raleway", sans-serif;
  background: transparent;
  border: 1px solid #0087D2;
  border-radius: 0;
  margin-top: 20px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  letter-spacing: 0.1em;
  color: #0087D2;
  font-weight: 500;
  padding: 13px 40px;
}
.action.primary:hover, .action.primary:active {
  background-color: #0087D2;
  border: 1px solid #0087D2;
  color: #FFFFFF;
}

div.mage-error[generated] {
  margin-top: 3px;
}

.abs-margin-for-forms-desktop,
form .fieldset .legend,
.column:not(.sidebar-main) form div.actions-toolbar,
.column:not(.sidebar-additional) form div.actions-toolbar,
.login-container form .fieldset:after {
  margin-left: 0;
}

form .fieldset > .legend {
  display: block;
  width: 100%;
  font-size: 18px;
  border-bottom: 1px solid #333232;
  font-weight: 500;
  margin-bottom: 15px;
  padding-bottom: 12px;
}

form .fieldset > .field:not(.choice) > .label,
form .fieldset > .field > .label {
  width: 100%;
  padding: 0;
  text-align: left;
  margin-left: 0;
  margin-bottom: 5px;
}

form .fieldset > .field > .control,
form .fieldset > .field:not(.choice) > .control {
  width: 100%;
}

form .fieldset > .field.choice:before,
form .fieldset > .field.no-label:before {
  padding: 0;
  width: auto;
}

.page-print .sections.nav-sections {
  display: none;
}

iframe {
  max-width: 100%;
}

:root {
  --background-color: #F5F5F5;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #000000;
  font-family: "Raleway", sans-serif;
}
body #page210 {
  background-color: var(--background-color);
  flex: 1;
}
body .full-row {
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  box-sizing: border-box;
}

.page.messages,
.checkout-container .messages {
  top: 13rem;
  height: max-content;
}

.banner-decoration {
  display: block;
  height: auto;
  width: 111%;
  transform: translate(-5%, 1px);
}

.product .imagewrapper img#placeholder-image {
  object-fit: contain !important;
  filter: opacity(0.5);
}

@media (width <= 768px) {
  .page.messages .messages .message {
    width: 90vw;
  }
}
/*
==================================
=== MEDIA QUERY 1440px and below ===
==================================
*/
/*
==================================
=== MEDIA QUERY 1024px and below ===
==================================
*/
/*
==================================
=== MEDIA QUERY 779px and below ===
==================================
*/
@media only screen and (max-width: 767px) {
  .checkout-index-index .nav-toggle {
    display: block;
  }
}
/*
==================================
=== MEDIA QUERY 640px and below ===
==================================
*/
/*
==================================
=== MEDIA QUERY 480px and below ===
==================================
*/
@media only screen and (max-width: 767px) {
  #html-body #page210 .amsearch-wrapper-block {
    grid-row: 2;
    grid-column: span 4;
    max-width: 100%;
  }
  #html-body #page210 .amsearch-wrapper-block .amsearch-result-section {
    grid-template-columns: 1fr;
    width: 100vw;
  }
  #html-body #page210 .amsearch-wrapper-block .amsearch-result-section .amsearch-product-list {
    grid-template-columns: 1fr;
    justify-items: unset;
    gap: 0;
  }
  #html-body #page210 .amsearch-wrapper-block .amsearch-result-section section[data-amsearch-analytics=block] {
    grid-column: span 2;
    grid-row: 2;
  }
}
@media only screen and (min-width: 768px) {
  .amsearch-button.-icon.-loupe.-loupe-trigger.-clear {
    display: none;
  }
  .amsearch-result-section section[data-amsearch-analytics=block] {
    max-width: 90%;
  }
  .amsearch-items-section {
    min-width: 150px;
  }
}
#page210 > header {
  top: 0;
  width: 100%;
  background-color: #000000;
  z-index: 2;
  position: relative;
}
#page210 > header .cms-intro-row {
  display: none;
}
#page210 > header .amsearch-result-section {
  max-width: 1200px;
  width: auto;
}
#page210 > header .amsearch-block-header .amsearch-title .amsearch-item.product-item {
  word-break: none;
}
#page210 > header .amsearch-input-wrapper {
  width: 100%;
}
#page210 > header .amsearch-input-wrapper input[type=text] {
  border-radius: 50px;
  background: #000000;
  border: 1px solid #F5F5F5;
  height: 40px;
  color: #F5F5F5;
}
#page210 > header .amsearch-link, #page210 > header .amsearch-highlight {
  color: #0087D2;
}
#page210 > header .amsearch-link:hover, #page210 > header .amsearch-link:focus, #page210 > header .amsearch-link:focus-visible, #page210 > header .amsearch-link:active, #page210 > header .amsearch-highlight:hover, #page210 > header .amsearch-highlight:focus, #page210 > header .amsearch-highlight:focus-visible, #page210 > header .amsearch-highlight:active {
  color: #EF0000;
}
#page210 > header .amsearch-form-block {
  justify-content: center !important;
}
@media (width > 767px) {
  #page210 > header .amsearch-form-block.-opened {
    max-width: 400px;
  }
}
#page210 > header .amsearch-result-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: 1;
  grid-row: 1;
  padding: 2em;
}
#page210 > header .amsearch-result-section section[data-amsearch-analytics=block] {
  grid-column: 2;
  grid-row: 1;
  border: 0 !important;
}
#page210 > header .amsearch-result-section .amsearch-product-list {
  display: grid !important;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 1em;
  justify-items: center;
}
#page210 > header .amsearch-result-section .amsearch-product-list li {
  list-style-type: none;
  max-width: 80% !important;
}
#page210 > header .amsearch-result-section .amsearch-product-list li:hover {
  background: #FFFFFF;
}
#page210 > header .amsearch-result-section .amsearch-message-block {
  grid-row: 3;
}
#page210 > header.sticky {
  position: fixed;
}
#page210 > header .header-top {
  background-color: #0087D2;
}
#page210 > header .header-top .full-row {
  padding-top: 10px;
  padding-bottom: 10px;
}
#page210 > header .header-top .full-row p {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0;
}
#page210 > header .header-top .full-row p a {
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
}
#page210 > header .header-top .full-row p a .fa-phone, #page210 > header .header-top .full-row p a .fa-envelope {
  display: block;
  width: 14px;
  aspect-ratio: 1;
}
#page210 > header .header-top .full-row p a .fa-phone:not(svg), #page210 > header .header-top .full-row p a .fa-envelope:not(svg) {
  font-size: 0;
}
#page210 > header .header-top .full-row p a:hover {
  color: black;
}
#page210 > header > .full-row {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media only screen and (max-width: 1024px) {
  #page210 > header > .full-row {
    grid-template-columns: repeat(4, auto);
  }
}
@media only screen and (max-width: 768px) {
  #page210 > header > .full-row {
    grid-template-columns: auto 1fr auto auto;
    gap: 10px;
  }
}
#page210 > header > .full-row .nav-toggle {
  position: static;
}
#page210 > header > .full-row .logo {
  margin: 0;
  max-width: none;
}
#page210 > header > .full-row .block.block-search {
  all: unset;
  border: 1px solid #FFFFFF;
  border-radius: 50px;
  padding: 2px 15px;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
}
@media only screen and (max-width: 768px) {
  #page210 > header > .full-row .block.block-search {
    border: none;
    padding: 0;
  }
}
#page210 > header > .full-row .block.block-search .minisearch {
  display: flex;
  align-items: center;
}
#page210 > header > .full-row .block.block-search .minisearch .field.search {
  width: 100%;
}
#page210 > header > .full-row .block.block-search .minisearch .actions {
  margin-left: auto;
}
#page210 > header > .full-row .block.block-search .minisearch .actions button {
  all: unset;
}
@media only screen and (max-width: 768px) {
  #page210 > header > .full-row .block.block-search .minisearch .actions button {
    display: none;
  }
}
#page210 > header > .full-row .block.block-search .minisearch .actions button svg {
  color: #FFFFFF;
  width: 11px;
  height: auto;
  aspect-ratio: 1;
}
#page210 > header > .full-row .block.block-search .minisearch .actions button::before {
  all: unset;
}
#page210 > header > .full-row .block.block-search #search {
  background-color: transparent;
  border: none;
  color: #FFFFFF;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
}
#page210 > header > .full-row .block.block-search #search::placeholder {
  text-transform: lowercase;
}
#page210 > header > .full-row .block.block-search .nested {
  display: none;
}
#page210 > header > .full-row .block.block-search .control {
  top: 100%;
  padding: 0;
  border-top: none;
}
@media only screen and (max-width: 768px) {
  #page210 > header > .full-row .block.block-search .control {
    position: absolute;
    background-color: #000000;
    left: 0;
    width: 100%;
    margin: 0;
    top: calc(100% - 30px);
    box-sizing: border-box;
  }
}
#page210 > header > .full-row nav.navigation {
  position: static;
  all: unset;
}
@media only screen and (max-width: 768px) {
  #page210 > header > .full-row nav.navigation {
    display: none;
  }
}
#page210 > header > .full-row nav.navigation .level0 .submenu .active > a {
  border-color: #0087D2;
}
#page210 > header > .full-row nav.navigation .level0 .submenu a:hover {
  background-color: #FFFFFF;
}
#page210 > header > .full-row nav.navigation .level0 .submenu a:hover .span:hover {
  color: #0087D2;
}
#page210 > header > .full-row nav.navigation ul {
  background-color: #000000;
  padding: 0;
}
#page210 > header > .full-row nav.navigation ul .submenu {
  background-color: #FFFFFF;
}
#page210 > header > .full-row nav.navigation ul li {
  position: static;
}
#page210 > header > .full-row nav.navigation ul li a span {
  color: #000000;
  font-weight: bold;
  font-size: 14px;
}
#page210 > header > .full-row nav.navigation ul li a span:hover {
  color: #0087D2;
}
#page210 > header > .full-row nav.navigation > ul {
  z-index: 10;
  border: none;
  position: static;
}
@media (width >= 1440px) {
  #page210 > header > .full-row nav.navigation > ul li {
    position: relative;
  }
}
#page210 > header > .full-row nav.navigation > ul li .submenu {
  min-width: 0;
}
#page210 > header > .full-row nav.navigation > ul li.level0 > a {
  color: #FFFFFF;
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: start;
  border: none !important;
  padding: 0 !important;
}
#page210 > header > .full-row nav.navigation > ul li.level0 > a span {
  color: #FFFFFF;
}
#page210 > header > .full-row nav.navigation > ul li.level0 > a span:hover {
  color: #0087D2;
}
#page210 > header > .full-row nav.navigation > ul li.level0 > a span.ui-menu-icon {
  position: static !important;
  transition: 0.3s;
  rotate: z 360deg;
}
#page210 > header > .full-row nav.navigation > ul li.level0 > a span.ui-menu-icon::after {
  font-size: 35px !important;
  font-weight: bold !important;
}
#page210 > header > .full-row nav.navigation > ul li.level0:hover > a span.ui-menu-icon {
  rotate: z 270deg;
}
#page210 > header > .full-row nav.navigation > ul li.level0 > .submenu[aria-expanded=true] {
  grid-template-columns: repeat(5, 210px);
  border: none;
  left: 0 !important;
}
@media (width > 768px) {
  #page210 > header > .full-row nav.navigation > ul li.level0 > .submenu[aria-expanded=true] {
    display: grid !important;
    top: calc(100% - 20px) !important;
  }
}
@media (width < 1440px) {
  #page210 > header > .full-row nav.navigation > ul li.level0 > .submenu[aria-expanded=true] {
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    width: 100%;
  }
}
#page210 > header > .full-row nav.navigation > ul li.level1 {
  display: grid;
  grid-template-rows: max-content 0fr;
  transition: ease-in 0.3s;
  height: max-content;
  position: relative;
}
#page210 > header > .full-row nav.navigation > ul li.level1:hover {
  grid-template-rows: max-content 1fr;
  transition: ease-out 0.3s;
}
#page210 > header > .full-row nav.navigation > ul li.level1:hover > a span.ui-menu-icon {
  rotate: z 0deg;
}
#page210 > header > .full-row nav.navigation > ul li.level1.active > a {
  border: none;
}
#page210 > header > .full-row nav.navigation > ul li.level1.active > a span {
  color: #0087D2;
}
#page210 > header > .full-row nav.navigation > ul li.level1 > a {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
}
#page210 > header > .full-row nav.navigation > ul li.level1 > a span {
  color: #000000;
}
#page210 > header > .full-row nav.navigation > ul li.level1 > a span:hover {
  color: #0087D2;
}
#page210 > header > .full-row nav.navigation > ul li.level1 > a span.ui-menu-icon {
  position: static !important;
  transition: 0.3s;
  rotate: z 90deg;
  width: max-content;
  height: max-content;
}
#page210 > header > .full-row nav.navigation > ul li.level1 > a span.ui-menu-icon::after {
  font-size: 35px !important;
  font-weight: bold !important;
}
#page210 > header > .full-row nav.navigation > ul li.level1:hover > .submenu {
  display: block !important;
}
#page210 > header > .full-row nav.navigation > ul li.level1 > .submenu {
  display: none !important;
  position: absolute;
  border: none;
  box-shadow: none;
  overflow: hidden;
  min-height: 0;
  background-color: #E2E1E1;
  left: 0 !important;
  right: auto;
  height: min-content;
}
@media (width > 768px) {
  #page210 > header > .full-row nav.navigation > ul li.level1 > .submenu {
    top: 100% !important;
  }
}
#page210 > header > .full-row nav.navigation > ul li.level1 > .submenu li a {
  padding: 3px 20px;
}
#page210 > header > .full-row nav.navigation > ul li.level1 > .submenu li a span {
  font-weight: normal;
}
#page210 > header > .full-row nav.navigation > ul li.level1:nth-child(n+2):nth-child(-n+5)::before {
  content: "";
  height: 100%;
  width: 0px;
  background: #D6D6D6;
  display: block;
  position: absolute;
  top: 0;
}
#page210 > header > .full-row .header-links {
  margin-left: auto;
  grid-column: -2;
  display: flex;
  align-items: center;
  gap: 10px;
}
#page210 > header > .full-row .header-links .website-link {
  position: relative;
  overflow: hidden;
  transition: ease-out 0.1s;
  isolation: isolate;
  background: #0087D2;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
}
#page210 > header > .full-row .header-links .website-link::before {
  background: #FFFFFF;
  z-index: -1;
}
#page210 > header > .full-row .header-links .website-link .brands-link {
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
}
#page210 > header > .full-row .header-links .website-link:hover {
  scale: 1.05;
  background-color: #FFFFFF;
}
#page210 > header > .full-row .header-links .website-link:hover * {
  color: #0087D2;
}
#page210 > header > .full-row .header-links .website-link:hover::before {
  scale: 1.05;
  translate: 0 0;
}
#page210 > header > .full-row .header-links .website-link p {
  margin-bottom: 0;
}
#page210 > header > .full-row .header-links .website-link a {
  display: block;
  padding: 9px 18px;
  color: inherit;
  font-weight: bold;
  font-size: 14px;
}
#page210 > header > .full-row .header-links .minicart-wrapper {
  line-height: 0;
  float: none;
  isolation: isolate;
  margin: 0;
}
#page210 > header > .full-row .header-links .minicart-wrapper .price-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 5px;
}
#page210 > header > .full-row .header-links .minicart-wrapper .action.showcart {
  margin-bottom: 0;
}
#page210 > header > .full-row .header-links .minicart-wrapper .action.showcart::before {
  all: unset;
}
#page210 > header > .full-row .header-links .minicart-wrapper .action.showcart .animationwrapper {
  position: relative;
  overflow: hidden;
  transition: ease-out 0.1s;
  isolation: isolate;
  background: #EF0000;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
  padding: 12px;
  padding-left: 13px;
  width: 38px;
  aspect-ratio: 1;
  border-radius: 100%;
}
#page210 > header > .full-row .header-links .minicart-wrapper .action.showcart .animationwrapper::before {
  background: #FFFFFF;
  z-index: -1;
}
#page210 > header > .full-row .header-links .minicart-wrapper .action.showcart .animationwrapper .brands-link {
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
}
#page210 > header > .full-row .header-links .minicart-wrapper .action.showcart .animationwrapper:hover {
  scale: 1.05;
  background-color: #FFFFFF;
}
#page210 > header > .full-row .header-links .minicart-wrapper .action.showcart .animationwrapper:hover * {
  color: #EF0000;
}
#page210 > header > .full-row .header-links .minicart-wrapper .action.showcart .animationwrapper:hover::before {
  scale: 1.05;
  translate: 0 0;
}
#page210 > header > .full-row .header-links .minicart-wrapper .counter.qty {
  position: absolute;
  left: 100%;
  bottom: 100%;
  translate: -60% 60%;
  aspect-ratio: 1;
  background-color: #0087D2;
  border-radius: 100%;
  z-index: 1;
}
#page210 > header > .full-row .header-links .block-minicart {
  line-height: 1;
}
#page210 > header > .full-row .header-links .block-minicart .minicart-items-wrapper {
  max-height: 400px;
}
#page210 > header > .full-row .header-links .block-minicart .top-cart-btn-checkout {
  margin-top: 20px;
}
#page210 > header > .full-row .header-links .block-minicart .items-total {
  margin: 0 0 30px 10px;
}
#page210 > header > .full-row .header-links .block-minicart .subtotal {
  float: right;
  text-align: initial;
  display: flex;
  gap: 5px;
}
#page210 > header > .full-row .header-links .block-minicart .subtotal .price-container .price-wrapper .price {
  font-size: 14px;
}
#page210 > header > .full-row .header-links .block-minicart .price-on-request {
  font-size: 14px;
}
#page210 > header > .full-row .header-links .block-minicart span.price {
  color: #000000;
  font-size: normal !important;
  font-weight: 400 !important;
}
#page210 > header > .full-row .header-links .block-minicart .product-item-pricing .price-wrapper {
  display: grid;
  justify-content: initial;
}
#page210 > header > .full-row .header-links .block-minicart .product-item-pricing .price-wrapper .price-including-tax,
#page210 > header > .full-row .header-links .block-minicart .product-item-pricing .price-wrapper .price-excluding-tax {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 5px;
}
#page210 > header > .full-row .amsearch-wrapper-block {
  margin: 0;
  padding: 0;
}
@media (width <= 767px) {
  #page210 > header > .full-row .amsearch-wrapper-block {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #000000;
  }
  #page210 > header > .full-row .amsearch-wrapper-block .amsearch-form-block {
    display: none;
  }
  #page210 > header > .full-row .amsearch-wrapper-block .amsearch-form-block.-opened {
    display: block;
  }
  #page210 > header > .full-row .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-input {
    border-radius: 0;
    border: none;
  }
  #page210 > header > .full-row .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-button {
    display: none;
  }
}
#page210 > header > .full-row .amsearch-wrapper-block .amsearch-result-section:not(:has(*)) {
  display: none;
}
#page210 > header > .full-row .amsearch-wrapper-block .amsearch-full_width-title {
  display: none;
}
#page210 > header > .full-row .amsearch-button.-icon:not(.-close) {
  background-size: contain;
}

@media only screen and (max-width: 768px) {
  .nav-before-open body {
    overflow: unset;
  }
  html:is(.nav-open, .nav-before-open) {
    overflow: hidden;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row .nav-toggle:after {
    display: none;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row nav.navigation {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: 0;
    background: #000000;
    isolation: isolate;
    overflow: auto;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row nav.navigation .parent .level-top:after {
    position: static;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row nav.navigation > ul {
    display: grid;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row nav.navigation > ul li.level0 {
    display: grid;
    align-items: center;
    padding: 0 20px;
    min-height: 60px;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row nav.navigation > ul li.level0 > a {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row nav.navigation > ul li.level0 > a .ui-icon {
    display: none;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row nav.navigation > ul li.level0 > .submenu {
    grid-template-columns: 1fr;
    background: none;
    width: 100%;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row nav.navigation > ul li.level0 > .submenu .ui-menu-item a {
    padding-left: 0;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row nav.navigation > ul li.level0 > .submenu li.level1.active a span {
    color: #0087D2;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row nav.navigation > ul li.level0 > .submenu li.level1 a:hover {
    background: inherit;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row nav.navigation > ul li.level0 > .submenu li.level1 a span {
    color: #FFFFFF;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row nav.navigation > ul li.level0 > .submenu li.level1 > .submenu {
    display: block !important;
    position: static;
    background-color: inherit;
    padding-left: 10px;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row nav.navigation > ul li.level0 > .submenu li.level1 > .submenu li.level2 a span {
    color: white;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row .block.block-search {
    z-index: 99;
  }
  html:is(.nav-open, .nav-before-open) #page210 > header .full-row .block.block-search .form.minisearch.active .control {
    margin-top: 30px;
    border: 1px solid white;
  }
}
footer .footer-contact {
  display: flex;
  justify-content: center;
  position: relative;
  isolation: isolate;
  padding: 100px 0 200px 0;
  overflow-x: clip;
}
@media only screen and (max-width: 768px) {
  footer .footer-contact {
    padding: 60px 0;
  }
}
footer .footer-contact .backgroundImage {
  position: absolute;
  inset: 0;
  z-index: -1;
  filter: brightness(0.2);
}
footer .footer-contact .backgroundImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
footer .footer-contact .full-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media only screen and (max-width: 768px) {
  footer .footer-contact .full-row {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
footer .footer-contact .full-row .contact-text {
  line-height: initial;
}
footer .footer-contact .full-row .contact-text .contactTitle,
footer .footer-contact .full-row .contact-text p {
  margin: 0;
  color: #FFFFFF;
}
footer .footer-contact .full-row .contact-text .contactTitle {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
footer .footer-contact .full-row .contact-text p {
  font-size: 34px;
  font-weight: bolder;
}
footer .footer-contact .full-row .contact-text .contactLinks {
  display: grid;
  gap: 20px;
  grid-auto-columns: max-content;
  padding: 0;
  list-style: none;
  margin: 0;
  margin-top: 40px;
}
footer .footer-contact .full-row .contact-text .contactLinks li {
  position: relative;
  overflow: hidden;
  transition: ease-out 0.1s;
  isolation: isolate;
  background: #0087D2;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
  margin: 0;
  overflow: hidden;
  border-radius: 50px;
  border: 1px solid;
  border-color: #0087D2;
  position: relative;
  isolation: isolate;
}
footer .footer-contact .full-row .contact-text .contactLinks li::before {
  background: #FFFFFF;
  z-index: -1;
}
footer .footer-contact .full-row .contact-text .contactLinks li .brands-link {
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
}
footer .footer-contact .full-row .contact-text .contactLinks li:hover {
  scale: 1.05;
  background-color: #FFFFFF;
}
footer .footer-contact .full-row .contact-text .contactLinks li:hover * {
  color: #0087D2;
}
footer .footer-contact .full-row .contact-text .contactLinks li:hover::before {
  scale: 1.05;
  translate: 0 0;
}
footer .footer-contact .full-row .contact-text .contactLinks li a {
  font-weight: bold;
  display: block;
  color: #FFFFFF;
  padding: 10px 20px;
  width: 100%;
  text-align: center;
  z-index: 1;
}
footer .footer-contact .full-row .contact-text .contactLinks li:last-child {
  position: relative;
  overflow: hidden;
  transition: ease-out 0.1s;
  isolation: isolate;
  background: none;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
  border: 1px solid #fff;
  backdrop-filter: saturate(1.8) blur(20px);
}
footer .footer-contact .full-row .contact-text .contactLinks li:last-child::before {
  background: #FFFFFF;
  z-index: -1;
}
footer .footer-contact .full-row .contact-text .contactLinks li:last-child .brands-link {
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
}
footer .footer-contact .full-row .contact-text .contactLinks li:last-child:hover {
  scale: 1.05;
  background-color: #FFFFFF;
}
footer .footer-contact .full-row .contact-text .contactLinks li:last-child:hover * {
  color: none;
}
footer .footer-contact .full-row .contact-text .contactLinks li:last-child:hover::before {
  scale: 1.05;
  translate: 0 0;
}
footer .footer-contact .full-row .contact-text .contactLinks li:last-child a {
  color: #FFFFFF;
}
footer .footer-contact .full-row .contact-text .contactLinks li:last-child a:hover {
  color: #EF0000;
}
footer .footer-contact .full-row .form.contact {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
}
footer .footer-contact .full-row .form.contact .field {
  position: relative;
}
footer .footer-contact .full-row .form.contact .field label {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}
footer .footer-contact .full-row .form.contact .field:is(.name, .phone) {
  grid-column: 1;
}
footer .footer-contact .full-row .form.contact .field.message {
  grid-column: 1/3;
}
footer .footer-contact .full-row .form.contact .field.message textarea {
  resize: none;
}
footer .footer-contact .full-row .form.contact .field.required label::after {
  content: "*";
}
footer .footer-contact .full-row .form.contact .field div.mage-error {
  color: #FFFFFF;
  position: absolute;
}
footer .footer-contact .full-row .form.contact legend {
  color: #FFFFFF;
}
footer .footer-contact .full-row .form.contact .field-recaptcha {
  position: absolute;
}
footer .footer-contact .full-row .form.contact .submit {
  position: relative;
  overflow: hidden;
  transition: ease-out 0.1s;
  isolation: isolate;
  background: #0087D2;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
  padding: 10px 20px;
  width: max-content;
  justify-self: flex-end;
}
footer .footer-contact .full-row .form.contact .submit::before {
  background: #FFFFFF;
  z-index: -1;
}
footer .footer-contact .full-row .form.contact .submit .brands-link {
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
}
footer .footer-contact .full-row .form.contact .submit:hover {
  scale: 1.05;
  background-color: #FFFFFF;
}
footer .footer-contact .full-row .form.contact .submit:hover * {
  color: #0087D2;
}
footer .footer-contact .full-row .form.contact .submit:hover::before {
  scale: 1.05;
  translate: 0 0;
}
@media only screen and (max-width: 768px) {
  footer .footer-contact .full-row .form.contact .submit {
    margin-left: auto;
    width: auto;
  }
}
footer .footer-contact .full-row .form.contact .submit span {
  display: block;
  color: #FFFFFF;
  width: 100%;
  text-align: center;
  font-family: "Raleway", sans-serif;
}
footer .footer-contact .decoration-container {
  position: absolute;
  bottom: 0;
}
footer .footer-contact .decoration-container .blackpath {
  fill: transparent;
}
footer .footer-contact .decoration-container .bluepath {
  color: #0087D2;
}
footer .footer-contact .decoration-container .redpath {
  color: #EF0000;
}
footer .footer-contact .decoration-container .whitepath {
  color: #000000;
}
footer .footer-bottom {
  background-color: #000000;
  padding-top: 60px;
  padding-bottom: 60px;
}
footer .footer-bottom .full-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  gap: 40px;
}
footer .footer-bottom .full-row > * {
  color: #FFFFFF;
}
footer .footer-bottom .full-row > * .title {
  font-size: 16px;
  font-weight: 800;
  margin: 0;
}
footer .footer-bottom .full-row > * .title + p {
  margin-top: 20px;
}
footer .footer-bottom .full-row > * ul,
footer .footer-bottom .full-row > * li {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
}
footer .footer-bottom .full-row > * a {
  color: #FFFFFF;
  position: relative;
}
footer .footer-bottom .full-row > * a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  background-color: #0087D2;
  transition: ease-out 0.3s;
  left: 50%;
  translate: -50% 0;
}
footer .footer-bottom .full-row > * a:hover {
  color: #0087D2;
}
footer .footer-bottom .full-row > * ul {
  margin-top: 20px;
  list-style: none;
}
footer .footer-bottom .full-row > * ul li p {
  display: flex;
}
footer .footer-bottom .full-row > * ul li p strong {
  margin-right: 5px;
  font-weight: 400;
}
footer .footer-bottom .full-row > *[class*=col] ul + .title {
  margin-top: 40px;
}
footer .footer-bottom .full-row > *[class*=col] ul {
  margin-top: 5px;
}
footer .footer-bottom .full-row .copyrights-column {
  margin-top: 10px;
  font-size: 1.6rem;
  margin-bottom: 0;
}
@media (width >= 500px) {
  footer .footer-bottom .full-row .copyrights-column {
    grid-column: span 2;
  }
}
footer .footer-bottom .full-row .newsletter-column .newsletter-form {
  display: grid;
  gap: 20px;
  margin-top: 15px;
}
footer .footer-bottom .full-row .newsletter-column .newsletter-form .email {
  border: 1px solid #FFFFFF;
  border-radius: 50px;
  background: transparent;
  color: #FFFFFF;
}
footer .footer-bottom .full-row .newsletter-column .newsletter-form .submit {
  position: relative;
  overflow: hidden;
  transition: ease-out 0.1s;
  isolation: isolate;
  background: #0087D2;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
  width: max-content;
}
footer .footer-bottom .full-row .newsletter-column .newsletter-form .submit::before {
  background: #FFFFFF;
  z-index: -1;
}
footer .footer-bottom .full-row .newsletter-column .newsletter-form .submit .brands-link {
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
}
footer .footer-bottom .full-row .newsletter-column .newsletter-form .submit:hover {
  scale: 1.05;
  background-color: #FFFFFF;
}
footer .footer-bottom .full-row .newsletter-column .newsletter-form .submit:hover * {
  color: #0087D2;
}
footer .footer-bottom .full-row .newsletter-column .newsletter-form .submit:hover::before {
  scale: 1.05;
  translate: 0 0;
}
footer .footer-bottom .full-row .newsletter-column .newsletter-form .submit span {
  display: block;
  color: #FFFFFF;
  width: 100%;
  text-align: center;
  z-index: 1;
  font-family: "Raleway", sans-serif;
}

#minicart-content-wrapper .actions:has(.secondary) {
  display: none;
}

#top-cart-btn-checkout {
  position: relative;
  overflow: hidden;
  transition: ease-out 0.1s;
  isolation: isolate;
  background: #0087D2;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
  padding: 10px;
  color: white;
}
#top-cart-btn-checkout::before {
  background: #FFFFFF;
  z-index: -1;
}
#top-cart-btn-checkout .brands-link {
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
}
#top-cart-btn-checkout:hover {
  scale: 1.05;
  background-color: #FFFFFF;
}
#top-cart-btn-checkout:hover * {
  color: #0087D2;
}
#top-cart-btn-checkout:hover::before {
  scale: 1.05;
  translate: 0 0;
}

#top-cart-btn-checkout:hover {
  color: #0087D2;
  border: 1px solid #0087D2;
}

fieldset#customer-email-fieldset .field .control .note {
  display: none !important;
}

section.cart-row .full-row .cart-container .cart.actions a::before, section.cart-row .full-row .cart-container .cart.actions button::before {
  background-color: rgba(255, 255, 255, 0);
}

@media (width <= 768px) {
  #shopping-cart-table td {
    display: table-cell;
    float: none;
  }
}
.phpro-cookie-notice {
  background-color: rgba(0, 0, 0, 0.8);
}
.phpro-cookie-notice .notice__description {
  color: #FFFFFF;
}
.phpro-cookie-notice .notice__actions {
  display: flex;
  gap: 20px;
}
.phpro-cookie-notice .notice__actions .action {
  position: relative;
  overflow: hidden;
  transition: ease-out 0.1s;
  isolation: isolate;
  background: #0087D2;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
  margin-top: 0;
}
.phpro-cookie-notice .notice__actions .action::before {
  background: #FFFFFF;
  z-index: -1;
}
.phpro-cookie-notice .notice__actions .action .brands-link {
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
}
.phpro-cookie-notice .notice__actions .action:hover {
  scale: 1.05;
  background-color: #FFFFFF;
}
.phpro-cookie-notice .notice__actions .action:hover * {
  color: #0087D2;
}
.phpro-cookie-notice .notice__actions .action:hover::before {
  scale: 1.05;
  translate: 0 0;
}

.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap {
  top: 45%;
  transform: translateY(-55%);
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .phpro-cookie-consent-modal {
  background-color: #F5F5F5;
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .phpro-cookie-consent-modal .consent-tabs-content {
  background-color: #F5F5F5;
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .phpro-cookie-consent-modal .consent-tabs-content .cookie-toggle-wrapper .cookie-toggle input:checked + .cookie-toggle-slider {
  background-color: #0087D2;
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .phpro-cookie-consent-modal .consent-tabs-content p.title {
  font-size: 30px;
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .action {
  position: relative;
  overflow: hidden;
  transition: ease-out 0.1s;
  isolation: isolate;
  background: #0087D2;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .action::before {
  background: #FFFFFF;
  z-index: -1;
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .action .brands-link {
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .action:hover {
  scale: 1.05;
  background-color: #FFFFFF;
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .action:hover * {
  color: #0087D2;
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .action:hover::before {
  scale: 1.05;
  translate: 0 0;
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .action:first-child {
  margin-right: 20px;
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .consent-tabs .consent-tab {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  background-color: transparent;
  border: 0;
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .consent-tabs .consent-tab.active {
  background-color: transparent;
}
.modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .consent-tabs .consent-tab:hover, .modals-wrapper .cookie-consent-newsletter-modal.modal-popup._show .modal-inner-wrap .consent-tabs .consent-tab.active {
  color: #0087D2;
}